import Vue from 'vue'
import "dayjs/locale/ja"; // ローカライズのために必要  
import dayjs from "dayjs"; // dayjs本体  
import relativeTime from "dayjs/plugin/relativeTime"; // 相対時間表示プラグイン  
import localizedFormat from "dayjs/plugin/localizedFormat"; // ローカライズフォーマットプラグイン  
dayjs.extend(relativeTime); // プラグインを導入  
dayjs.extend(localizedFormat); // プラグインを導入  
dayjs.locale("ja"); // ローカライズ  

let rateFormat = function(num) {
  return `${parseInt(num * 100)}%`
}
let numberFormat = function(num) {
  return (num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}
let moneyFormat = function(num) {
  return (
    "￥" +
    (num || 0)
      .toString()
      .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","))
  );
}
let uppercaseFirst = function(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
let truncate = function(value, length) {
  if(value === null) return ""
  var length = length ? parseInt(length) : 20;
  var ommision = '...'

  if (value.length <= length) {
    return value;
  }

  return value.substring(0, length) + ommision;
}
let timeToYYYYMMDDWeekday = function(time){
  return dayjs(time).format("YYYY/MM/DD(ddd)")
}
let timeToYYYYMMDDHHMMSS = function(time){
  return dayjs(time).format("YYYY/MM/DD HH:mm:ss")
}
let timeToYYYYMMDDHHMM = function(time){
  return dayjs(time).format("YYYY/MM/DD HH:mm")
}
let timeToYYYYMM = function(time){
  return dayjs(time).format("YYYY/MM")
}
let timeToYYYYMMDD = function(time){
  return dayjs(time).format("YYYY/MM/DD")
}
let timeToMMDD = function(time){
  return dayjs(time).format("MM/DD")
}
let timeToHHMM = function(time){
  return dayjs(time).format("HH:mm")
}
let booleanToCanCanot = function(boolean){
  return boolean ? "可" : "不可"
}
let toI18n = function(data, locale){
  const data_path = `data.${locale}`
  let str = eval(data_path)
  if(str === null || str === "") str = data.ja
  return str
}

Vue.filter('rateFormat', rateFormat)
Vue.filter('numberFormat', numberFormat)
Vue.filter('moneyFormat', moneyFormat)
Vue.filter('uppercaseFirst', uppercaseFirst)
Vue.filter('truncate', truncate)
Vue.filter('timeToYYYYMMDDWeekday', timeToYYYYMMDDWeekday)
Vue.filter('timeToYYYYMMDDHHMMSS', timeToYYYYMMDDHHMMSS)
Vue.filter('timeToYYYYMMDDHHMM', timeToYYYYMMDDHHMM)
Vue.filter('timeToYYYYMM', timeToYYYYMM)
Vue.filter('timeToYYYYMMDD', timeToYYYYMMDD)
Vue.filter('timeToMMDD', timeToMMDD)
Vue.filter('timeToHHMM', timeToHHMM)
Vue.filter('booleanToCanCanot', booleanToCanCanot)
Vue.filter('toI18n', toI18n)