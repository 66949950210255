export const CommonImageThumbnail = () => import('../../components/common/ImageThumbnail.vue' /* webpackChunkName: "components/common-image-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const CommonPageHeader = () => import('../../components/common/PageHeader.vue' /* webpackChunkName: "components/common-page-header" */).then(c => wrapFunctional(c.default || c))
export const CommonUserIcon = () => import('../../components/common/UserIcon.vue' /* webpackChunkName: "components/common-user-icon" */).then(c => wrapFunctional(c.default || c))
export const OrderItemCountChanger = () => import('../../components/order/ItemCountChanger.vue' /* webpackChunkName: "components/order-item-count-changer" */).then(c => wrapFunctional(c.default || c))
export const OrderItemDetailModal = () => import('../../components/order/ItemDetailModal.vue' /* webpackChunkName: "components/order-item-detail-modal" */).then(c => wrapFunctional(c.default || c))
export const OrderItemPrice = () => import('../../components/order/ItemPrice.vue' /* webpackChunkName: "components/order-item-price" */).then(c => wrapFunctional(c.default || c))
export const OrderOptions = () => import('../../components/order/OrderOptions.vue' /* webpackChunkName: "components/order-options" */).then(c => wrapFunctional(c.default || c))
export const UtilAlert = () => import('../../components/util/Alert.vue' /* webpackChunkName: "components/util-alert" */).then(c => wrapFunctional(c.default || c))
export const UtilConfirmDialog = () => import('../../components/util/ConfirmDialog.vue' /* webpackChunkName: "components/util-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const UtilLoading = () => import('../../components/util/Loading.vue' /* webpackChunkName: "components/util-loading" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
