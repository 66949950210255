import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _68511c79 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _3bc821e4 = () => interopDefault(import('../pages/checkin.vue' /* webpackChunkName: "pages/checkin" */))
const _77ed8e69 = () => interopDefault(import('../pages/init.vue' /* webpackChunkName: "pages/init" */))
const _27961138 = () => interopDefault(import('../pages/order_histories.vue' /* webpackChunkName: "pages/order_histories" */))
const _6d8ee518 = () => interopDefault(import('../pages/timeout.vue' /* webpackChunkName: "pages/timeout" */))
const _679e7bc9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart/",
    component: _68511c79,
    pathToRegexpOptions: {"strict":true},
    name: "cart"
  }, {
    path: "/checkin/",
    component: _3bc821e4,
    pathToRegexpOptions: {"strict":true},
    name: "checkin"
  }, {
    path: "/init/",
    component: _77ed8e69,
    pathToRegexpOptions: {"strict":true},
    name: "init"
  }, {
    path: "/order_histories/",
    component: _27961138,
    pathToRegexpOptions: {"strict":true},
    name: "order_histories"
  }, {
    path: "/timeout/",
    component: _6d8ee518,
    pathToRegexpOptions: {"strict":true},
    name: "timeout"
  }, {
    path: "/",
    component: _679e7bc9,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
