import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';

// 追加・削除後の価格
const caluculateCartItem = (order) => {
  let selectedItemSubtotalPrice = 0
  selectedItemSubtotalPrice = order.selectedItem.price - order.selectedItem.discount_price
  for(let i = 0; i < order.selectedItemOptions.length; i++){
    let io = order.selectedItemOptions[i]
    for(let ii = 0; ii < io.settings.length; ii++){
      let setting = io.settings[ii]
      selectedItemSubtotalPrice += setting.price * setting.count
    }
  }
  order.selectedItemSubtotalPrice = selectedItemSubtotalPrice
  order.selectedItemTotalPrice = selectedItemSubtotalPrice * order.selectedItemCount
  return order
}

const getDefaultState = () => {
  return {
    shop: null,
    group: null,
    customer: null,

    // cart
    newOrders: [],

    // orders
    order_sets: [],
    order_sets_pager: {
      total_count: 0,
      total_pages: 0,
      current_page: 0,
      next_page: 1,
      prev_page: 0,  
    }, 

    // master
    sortedCategories: [],
    categories: [],
    items: [],
    options: [],
    freeboxes: [],
    vikings: [],
    staffs: [],
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setShop(state, shop) {
    state.shop = shop
  },
  setGroup(state, group) {
    state.group = group
  },
  setCustomer(state, customer) {
    state.customer = customer
  },

  iniSetOrders(state, payload) {
    state.order_sets = payload.order_sets
    state.orders_pager = payload.pages
  },
  addSetOrders(state, payload) {
    state.order_sets = state.order_sets.concat(payload.order_sets)
    state.order_sets = _.uniqBy(state.order_sets, 'id')
    state.order_sets_pager = payload.pages
  },

  // カテゴリツリーにソートされたアイテムを入れる
  setMasters(state, payload){
    // sort
    let categories = _.orderBy(payload.categories, 'sort', 'asc');
    let items = _.orderBy(payload.items, 'sort', 'asc');
    let options = _.orderBy(payload.options, 'sort', 'asc');
    let vikings = _.orderBy(payload.vikings, 'sort', 'asc');
    let freeboxes = _.orderBy(payload.freeboxes, 'sort', 'asc');
    let staffs = _.orderBy(payload.staffs, 'sort', 'asc');

    // make obj
    let newCategories = []
    categories.forEach(cat => {
      let lists = []
      lists = lists.concat(items.filter(it => {return it.category_id === cat.id}))
      lists = lists.concat(freeboxes.filter(fb => {return fb.category_id === cat.id}))
      lists = lists.concat(vikings.filter(vk => {return vk.category_id === cat.id}))
      lists = _.orderBy(lists, 'sort', 'asc');
      let newCategory = _.cloneDeep(cat)
      newCategory.lists = lists
      newCategories.push(newCategory)
    })

    // set
    state.sortedCategories = newCategories
    state.categories = categories
    state.items = items
    state.options = options
    state.vikings = vikings
    state.freeboxes = freeboxes
    state.staffs = staffs
  },

  resetCart(state){
    state.newOrders = []
  },
  addCart(state, newOrder){
    state.newOrders.push(newOrder)
  },

  // カート内部で追加
  decreaseCartItem(state, order_index){
    console.log("decreaseCartItem")
    let selectedOrder = state.newOrders[order_index]

    // アイテム特定
    let selectedItem = null
    state.sortedCategories.forEach(c => {
      c.lists.forEach(l => {
        if(l.uid === selectedOrder.selectedItem.uid) selectedItem = selectedOrder.selectedItem
      })
    })

    if(selectedOrder.selectedItemCount > 1 && selectedOrder.selectedItemCount > selectedItem.min_order_size){
      console.log(`decreaseCartItem > selectedItem.min_order_size`)
      selectedOrder.selectedItemCount -= 1
      selectedOrder = caluculateCartItem(selectedOrder)
    }else{
      console.log("decreaseCartItem 0")
      let deleteNewOrders = []
      for(let i = 0; i < state.newOrders.length; i++){
        if(i !== order_index){
          deleteNewOrders.push(state.newOrders[i])
        }
      }
      state.newOrders = deleteNewOrders
    }    
  },
  increaseCartItem(state, order_index){
    let selectedOrder = state.newOrders[order_index]
    selectedOrder.selectedItemCount += 1
    selectedOrder = caluculateCartItem(selectedOrder)
  },
  caluculateCartItem(state, {order, order_index}){
    let selectedItemPrice = 0
    selectedItemPrice = order.selectedItem.price
    for(let i = 0; i < order.selectedItemOptions.length; i++){
      let io = order.selectedItemOptions[i]
      for(let ii = 0; ii < io.settings.length; ii++){
        let setting = io.settings[ii]
        selectedItemPrice += setting.price * setting.count
      }
    }
    order.selectedItemPrice = selectedItemPrice
    order.selectedItemPriceSum = selectedItemPrice * order.selectedItemCount
  },
}
export const getters = {
  // 新規追加オーダーのトータル価格
  newOrderTotalPrice(state){
    let price = 0
    state.newOrders.forEach(no => {
      price += no.selectedItemTotalPrice
    })
    return price
  },
  // 新規追加オーダーのトータル数
  newOrderTotalSize(state){
    let size = 0
    state.newOrders.forEach(no => {
      size += no.selectedItemCount
    })
    return size
  },
  currentViking(state){
    let end_at = state.group.viking_end_at
    if(end_at === null) return false
    if(dayjs(end_at).isBefore(dayjs())) return false
    let viking = _.find(state.vikings, ["id", state.group.viking_id])
    return {
      viking: viking,
      viking_name: state.group.viking_name,
      viking_member_size: state.group.viking_member_size,
      viking_start_at: state.group.viking_start_at,
      viking_end_at: state.group.viking_end_at,
      viking_last_order_at: state.group.viking_last_order_at,
    }
  },
}
export const actions = {
  async getShop(context, shop_uid){
    await httpClient
    .get(`/cus/shop/info.json?shop_uid=${shop_uid}`)
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setShop", res.data.data.shop)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  async getMasters(context){
    await httpClient
    .get(`/cus/shop/masters.json`)
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setMasters", res.data.data)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  async getGroup(context){
    await httpClient
    .get(`/cus/group.json`)
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setGroup", res.data.data.group)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  async getOrders(context, payload){
    await httpClient
    .get(`/cus/group/orders.json?page=${payload.page}`)
    .then(async (res) => {
      if (res.data.status === 'success') {
        // 追加か新規か
        if(payload.isReset === true){
          context.commit("iniSetOrders", res.data.data)
        }else[
          context.commit("addSetOrders", res.data.data)
        ]
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

}
