import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';

const getDefaultState = () => {
  return {
    isGroupChannleSubscribed: false,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // contact
  updateGroupChannelSubscribed(state, payload) {
    state.isGroupChannleSubscribed = payload
  },
}
export const actions = {
  async channelSubscribe(context, channel){
    if (window.storeCtl.state.auth.authToken === null) return
    if (channel === "GroupChannel" && context.state.isGroupChannleSubscribed === true) return

    $nuxt.$cable.subscriptions.create({
      channel: channel,
      auth_token: window.storeCtl.state.auth.authToken,
    }, {
      connected: () => {
        console.log(`${channel} conected`)
        if(channel === "GroupChannel") context.commit("updateGroupChannelSubscribed", true)
      },
      disconnected: () => {
        console.log(`${channel} disconnected`)
      },
      received: async (data) => {
        console.log(`${channel} received`, data)

        // FIXME:ここに処理を書く
        if(data.action_type === "shop"){
          window.storeCtl.dispatch('shop/getShop')
        }else if(data.action_type === "master"){
          window.storeCtl.dispatch('shop/getMasters')
        }else if(data.action_type === "group"){
          window.storeCtl.dispatch('shop/getGroup')
        }else if(data.action_type === "order"){
          window.storeCtl.dispatch('shop/getOrders', {page: 1, isReset: true})
        }
      },
    })
  },

  //// チャネルの購読をとく
  removeSpecifiedChannel(context, channel) {
    // console.log('removeSpecifiedChannel channel: ' + channel);
    var subscriptions = $nuxt.$cable.subscriptions['subscriptions'];
    subscriptions.forEach((subscription) => {
      let identifier = subscription.identifier
      let obj = JSON.parse(identifier);
      // console.log("before channel", channel, obj.channel)
      if (channel == obj.channel) {
        // console.log("succes delete", channel)
        $nuxt.$cable.subscriptions.remove(subscription);
        if(channel === "GroupChannel") context.commit("updateGroupChannelSubscribed", false)
      }
    })
  },
}
