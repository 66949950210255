import Vue from 'vue'
import { isDev } from '~/utils/env'

let appConfigs = {}
if (isDev()) {
  // appConfigs.baseUrl = "http://localhost:3000"
} else {
  // appConfigs.baseUrl = "https://captors.me"
}

Vue.prototype.$appConfigs = appConfigs