//
//
//
//
//
//
//
//
//
//
//
//
//

import Common from '~/mixins/common'
import UserIcon from '~/components/common/UserIcon'
import Alert from '~/components/util/Alert'
import Loading from '~/components/util/Loading'
import ConfirmDialog from '~/components/util/ConfirmDialog'

export default {
  name: "default",
  mixins: [Common],
  components: {
    Alert,
    Loading,
    ConfirmDialog,
    UserIcon,
  },
  data() {
    return {
    }
  },
  async mounted() {
    let self = this
    self.$nuxt.$root.$confirm = this.$refs.confirm
  },
  computed: {
  },
  methods: {
  },
}
