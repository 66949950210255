//
//
//
//
//
//
//
//
//
//

export default {
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: null
    }
  }
}
