import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';

const getDefaultState = () => {
  return {
    index_height: 0,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  set_inex_height(state, height) {
    state.index_height = height
  },
}
export const getters = {
}
export const actions = {
}
