import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';

const getDefaultState = () => {
  return {
    // locale
    locale_code: null,

    // contact
    firstContact: false,
    trafficSource: null,  

    // beofre
    shop_uid: null,
    table_uid: null,
    customer_uid: null,

    // auth
    authToken: null,
    shop_id: null,
    group_id: null,
    customer_id: null,

    useLineLogin: false,
    isLineLoggedIn: false,
    line: {
      userId: null,
      displayName: null,
      pictureUrl: null,
      friendFlag: false,
    },
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // locale
  setLocale(state, locale_code) {
    state.locale_code = locale_code
  },
  // contact
  firstContactDone(state, payload) {
    state.firstContact = true
  },
  setTrafficSource(state, payload){
    state.trafficSource = payload
  },

  //
  setInitParams(state, payload) {
    state.shop_uid = payload.shop_uid
    state.table_uid = payload.table_uid
    state.useLineLogin = payload.useLineLogin
  },
  setCustomerUid(state, uid) {
    state.customer_uid = uid
  },
  
  // auth
  setAuthToken(state, payload) {
    state.authToken = payload.auth_token
    state.shop_id = payload.shop_id
    state.group_id = payload.group_id
  },
  reset(state) {
    // action cable disconnect
    // window.storeCtl.dispatch("actionCable/removeSpecifiedChannel", "GroupChannel")
    Object.assign(state, getDefaultState())
    window.storeCtl.commit("shop/reset")
  },
  logout(state) {
    // action cable disconnect
    // window.storeCtl.dispatch("actionCable/removeSpecifiedChannel", "GroupChannel")
    Object.assign(state, getDefaultState())
    window.storeCtl.commit("shop/reset")
    $nuxt.$router.push(`/timeout/`)
  },

  // line
  setIsLineLoggedIn(state, payload){
    state.isLineLoggedIn = payload
  },
  setLineProfile(state, payload){
    state.line = payload
  },

}
export const getters = {
  isLogin(state) {
    return !!state.authToken
  },
}
export const actions = {
}
