import _ from 'lodash';
import { sleep } from '~/utils/tool'

const getDefaultState = () => {
  return {
    fireStoreObserver: null,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  resetAll(state){
    Object.assign(state, getDefaultState())
  },
  // contact
  setFireStoreObserver(state, fireStoreObserver) {
    state.fireStoreObserver = fireStoreObserver
  },
}
export const actions = {
  // async init(context){
  //   if (window.storeCtl.state.shop.group === null) return
  //   if (window.storeCtl.state.fireStore.fireStoreObserver) return

  //   // データの監視
  //   const doc = this.$fire.firestore.collection('groups').doc(window.storeCtl.state.shop.group.uid);
  //   let fireStoreObserver = doc.onSnapshot(async docSnapshot => {
  //     let data = docSnapshot.data()
  //     console.log(`Received doc snapshot:`, data)

  //     // FIXME:ここに処理を書く
  //     if(data.action_type === "shop"){
  //       window.storeCtl.dispatch('shop/getShop')
  //     }else if(data.action_type === "master"){
  //       window.storeCtl.dispatch('shop/getMasters')
  //     }else if(data.action_type === "group"){
  //       window.storeCtl.dispatch('shop/getGroup')
  //     }else if(data.action_type === "order"){
  //       window.storeCtl.dispatch('shop/getOrders', {page: 1, isReset: true})
  //     }
  //   }, err => {
  //     console.log(`Encountered error: ${err}`);
  //   });

  //   context.commit("setFireStoreObserver", fireStoreObserver)    
  // },

  // //// チャネルの購読をとく
  // destroy(context) {
  //   if (window.storeCtl.state.fireStore.fireStoreObserver === null) return
  //   window.storeCtl.state.fireStore.fireStoreObserver()
  //   context.commit("resetAll")  
  // },
}

// firestore https://firebase.google.com/docs/firestore/query-data/listen?hl=ja


// データ取得
// let obj = this.$fire.firestore.collection("shops").doc("dFmvYLF3xZzfW30OhhZA")
// obj.get() // 取得
//   .then(response => {
//     console.log("obj response", response.data())
//     // 取得成功時の処理
//   }).catch(error => {
//     // 取得失敗時の処理
//   })

// // データ更新
// this.$fire.firestore.collection("shops").doc("dFmvYLF3xZzfW30OhhZA").set({
//     name: "harusou2"
//     // ドキュメントオブジェクト
//   }, { merge: true }).then(response => {
//     // 更新成功時の処理
//     console.log("obj set", response)
//   }).catch(error => {
//     // 更新失敗時の処理
//   })

//　データ追加
// this.$fire.firestore.collection("shops").add({
//     // ドキュメントオブジェクト
//     name: "harusou!!!",
//     id: 12345,
//   }).then(response => {
//     // 登録成功時の処理
//     console.log("obj add", response)
//   }).catch(error => {
//     // 登録失敗時の処理
//   })

// // データの監視
// const doc = this.$fire.firestore.collection('shops').doc('dFmvYLF3xZzfW30OhhZA');
// self.fireStoreObserver = doc.onSnapshot(docSnapshot => {
//   let action_data = docSnapshot.data()
//   console.log(`Received doc snapshot:`, action_data);
//   // ...
// }, err => {
//   console.log(`Encountered error: ${err}`);
// });