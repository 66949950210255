import { mapState } from 'vuex'
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import Compressor from 'compressorjs'

export default {
  computed: {
    ...mapState({
      locale_code: state => state.auth.locale_code,
      auth: state => state.auth,
      shop: state => state.shop.shop,
      group: state => state.shop.group,
      customer: state => state.shop.customer,
      newOrders: state => state.shop.newOrders,
      order_sets: state => state.shop.order_sets,
      orders_pager: state => state.shop.orders,
      sortedCategories: state => state.shop.sortedCategories,
      items: state => state.shop.items,
      options: state => state.shop.options,
      staffs: state => state.shop.staffs,
    }),

    // 画面表示
    orderTotalSize(){
      let self = this
      let size = 0
      self.order_sets.forEach(os => {
        os.orders.forEach(o => {
          if(o.status !== "cancel") size += o.count 
        })
      })
      return size
    },
    orderTotalPrice(){
      let self = this
      let price = 0
      self.order_sets.forEach(os => {
        os.orders.forEach(o => {
          if(o.status !== "cancel") price += o.price_sum 
        })
      })
      return price
    },    
    taxModeText(){
      return this.shop.is_include_tax ? this.$t('item.tax') : this.$t('item.tax_ex')
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },

    itemTaxTypeText(tax_rate){
      if(tax_rate === 0) return this.$t('item.tax_0')
      if(tax_rate === 8) return this.$t('item.tax_8')
      if(tax_rate === 10) return this.$t('item.tax_10')
    },
  }
}
